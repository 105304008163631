import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import ActionForm from "./action-form"

const SuccessStory = ({
  title,
  customerName,
  customerLocation,
  customerDescription,
  customerIndustries,
  logo,
  link,
  style,
  children,
}) => {
  return (
    <Layout>
      <SEO
        title={customerName + " Success Story | Celantur"}
        description={
          customerName +
          " preserves privacy on images and videos with Celantur technology."
        }
      />

      <section
        className="container-fluid pt-5 pt-md-12 pb-5 pb-md-8 overlay overlay-black overlay-80 bg-cover text-center"
        style={style}
      >
        <div className="container">
          <span className="badge badge-success-soft badge-pill mb-3">
            <span className="h6 text-uppercase">Success Story</span>
          </span>
          <h1 className="display-4 font-weight-bold text-white">
            {customerName}: {title}
          </h1>
        </div>
      </section>

      <section className="pt-5 pt-md-8 mb-8">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">

              {children}

            </div>
            <div className="col-12 col-md-4">
              <div className="card shadow-light-lg mb-5 card-border border-success">
                <div className="card-body">
                  <h4>Tell us your privacy challenge. We're here to help.</h4>
                  <Link
                    to="/contact/"
                    role="button"
                    className="btn btn-sm btn-success my-2 event-contact"
                  >
                    Contact us
                  </Link>
                </div>
              </div>

              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>About <a href={link}>{customerName}</a></h4>

                  <p className="font-size-sm text-gray-800">
                    {customerDescription}
                  </p>

                  <p className="font-size-sm text-gray-800">
                    Industries: {customerIndustries}
                  </p>

                  <p className="font-size-sm text-gray-800">
                    Location: {customerLocation}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <ActionForm source="success-story" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SuccessStory
